import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import icon mắt
import '../css/Sale.css';
import '../css/Modal.css';
import {
    getAccount,
    updateDisplayName,
    updateCustomPassword,
    createTelesaleAccount,
    deleteTelesaleAccount,
    updateTelesaleSettingProject,
    updateTelesaleSettingDatasheet,
    updateTelesaleSettingNetwork,
    getListTelesaleWithSetting,
    getListTelesaleWithStatistic,
    createSaleProject,
    deleteSaleProject,
    getListSaleProject,
    createSaleDatasheet,
    deleteSaleDatasheet,
    getListSaleDatasheet,
    getSaleDatasheetListPhoneNumber,
    uploadSaleDatasheetCustomer,
} from './API';

const Sale = () => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [selectedSection, setSelectedSection] = useState('telesale-setting');
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
    const years = [2024, 2025, 2026];

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };
    
    const [showCreateTelesaleAccountModal, setShowCreateTelesaleAccountModal] = useState(false);
    const [newTelesaleAccountCustomLoginID, setNewTelesaleAccountCustomLoginID] = useState('');
    const [newTelesaleAccountDisplayName, setNewTelesaleAccountDisplayName] = useState('');

    const [showUpdateTelesaleSettingProjectModal, setShowUpdateTelesaleSettingProjectModal] = useState(false);
    const [showUpdateTelesaleSettingDatasheetModal, setShowUpdateTelesaleSettingDatasheetModal] = useState(false);
    const [showUpdateTelesaleSettingNetworkModal, setShowUpdateTelesaleSettingNetworkModal] = useState(false);

    const [showCreateSaleProjectModal, setShowCreateSaleProjectModal] = useState(false);
    const [newSaleProjectDisplayName, setNewSaleProjectDisplayName] = useState('');

    const [showCreateSaleDatasheetModal, setShowCreateSaleDatasheetModal] = useState(false);
    const [newSaleDatasheetDisplayName, setNewSaleDatasheetDisplayName] = useState('');

    const [showSaleDatasheetModal, setShowSaleDatasheetModal] = useState(false);

    const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [listSaleProject, setListSaleProject] = useState([]);
    const [listSaleDatasheet, setListSaleDatasheet] = useState([]);
    const [openDatasheetFolders, setOpenDatasheetFolders] = useState(new Set());

    const [updatingTelesaleSettingAccountID, setUpdatingTelesaleSettingAccountID] = useState('');
    const [viewingSaleDatasheetID, setViewingSaleDatasheetID] = useState(0);
    const [viewingSaleDatasheetListPhoneNumber, setViewingSaleDatasheetListPhoneNumber] = useState([]);
    const [viewingSaleDatasheetTotal, setViewingSaleDatasheetTotal] = useState(0);
    const [viewingSaleDatasheetViettel, setViewingSaleDatasheetViettel] = useState(0);
    const [viewingSaleDatasheetVinaphone, setViewingSaleDatasheetVinaphone] = useState(0);
    const [viewingSaleDatasheetMobiphone, setViewingSaleDatasheetMobiphone] = useState(0);
    const [newSaleDatasheetCustomers, setNewSaleDatasheetCustomers] = useState('');
    const [correctedSaleDatasheetCustomers, setCorrectedSaleDatasheetCustomers] = useState([]);
    
    const [listTelesaleWithSetting, setListTelesaleWithSetting] = useState([]);

    const [saleStatistic, setSaleStatistic] = useState({ total: 0, statistic: [] });
    const [listTelesaleWithStatistic, setListTelesaleWithStatistic] = useState([]);

    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
            loadListSaleProject();
            loadListSaleDatasheet();
            loadListTelesaleWithSetting();
            loadListTelesaleWithStatistic();
        }
        else {
            navigate('/');
        }
    }, [navigate]);

    const loadListTelesaleWithSetting = async () => {
        try {
            const data = await getListTelesaleWithSetting();
            const isSuccess = data.success;
            if (isSuccess === true) {
                const listTelesaleWithSetting = data.list_telesale_with_setting;
                setListTelesaleWithSetting(listTelesaleWithSetting);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const loadListTelesaleWithStatistic = async () => {
        try {
            const userData = {
                month: selectedMonth,
                year: selectedYear
            };
            setListTelesaleWithStatistic([]);
            const data = await getListTelesaleWithStatistic(userData);
            const isSuccess = data.success;
            if (isSuccess === true) {
                const listTelesaleWithStatistic = data.list_telesale_with_statistic;
                setListTelesaleWithStatistic(listTelesaleWithStatistic);

                const calculatedSaleStatistic = listTelesaleWithStatistic.reduce(
                    (sale, telesale) => {
                      const telesaleStatistic = telesale.telesaleStatistic || { total: 0, statistic: [] };
                      sale.total += telesaleStatistic.total;
                      telesaleStatistic.statistic.forEach((value, index) => {
                        sale.statistic[index] = (sale.statistic[index] || 0) + value;
                      });
              
                      return sale;
                    },
                    { total: 0, statistic: Array(31).fill(0) }
                );
                setSaleStatistic(calculatedSaleStatistic);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const loadListSaleProject = async () => {
        try {
            const data = await getListSaleProject();
            const isSuccess = data.success;
            if (isSuccess === true) {
                setListSaleProject(data.list_sale_project);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const loadListSaleDatasheet = async () => {
        try {
            const data = await getListSaleDatasheet();
            const isSuccess = data.success;
            if (isSuccess === true) {
                const buildTree = (items) => {
                    const map = {};
                    const roots = [];
              
                    items.forEach(item => {
                      map[item.id] = { ...item, children: [] };
                    });
              
                    items.forEach(item => {
                      if (item.parent_folder === 0) {
                        roots.push(map[item.id]);
                      } else {
                        map[item.parent_folder]?.children.push(map[item.id]);
                      }
                    });
              
                    return roots;
                };

                const saleDatasheetTreeData = buildTree(data.list_sale_datasheet);
                setListSaleDatasheet(saleDatasheetTreeData);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateTelesaleAccount = async () => {
        try {
            const userData = {
                custom_login_id: newTelesaleAccountCustomLoginID,
                display_name: newTelesaleAccountDisplayName,
            };
            const data = await createTelesaleAccount(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSetting();
                await loadListTelesaleWithStatistic();
                setShowCreateTelesaleAccountModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleDeleteTelesaleAccount = async (telesale) => {
        try {
            const userData = {
                account_id: telesale.account_id,
            };
            const data = await deleteTelesaleAccount(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSetting();
                await loadListTelesaleWithStatistic();
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleUpdateTelesaleSettingProject = async(project) => {
        try {
            const userData = {
                account_id: updatingTelesaleSettingAccountID,
                project: project,
            };
            const data = await updateTelesaleSettingProject(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSetting();
                setShowUpdateTelesaleSettingProjectModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleUpdateTelesaleSettingDatasheet = async(datasheet) => {
        try {
            const userData = {
                account_id: updatingTelesaleSettingAccountID,
                datasheet: datasheet,
            };
            const data = await updateTelesaleSettingDatasheet(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSetting();
                setShowUpdateTelesaleSettingDatasheetModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleUpdateTelesaleSettingNetwork = async(network) => {
        try {
            const userData = {
                account_id: updatingTelesaleSettingAccountID,
                network: network,
            };
            const data = await updateTelesaleSettingNetwork(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSetting();
                setShowUpdateTelesaleSettingNetworkModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateSaleProject = async () => {
        try {
            const userData = {
                display_name: newSaleProjectDisplayName,
            };
            const data = await createSaleProject(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleProject();
                setShowCreateSaleProjectModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleDeleteSaleProject = async (project) => {
        try {
            const userData = {
                id: project.id,
            };
            const data = await deleteSaleProject(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleProject();
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateSaleDatasheet = async () => {
        try {
            const userData = {
                is_folder: 0,
                parent_folder: 0,
                display_name: newSaleDatasheetDisplayName,
            };
            const data = await createSaleDatasheet(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleDatasheet();
                setShowCreateSaleDatasheetModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleDeleteSaleDatasheet = async (datasheet) => {
        try {
            const userData = {
                id: datasheet.id,
            };
            const data = await deleteSaleDatasheet(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleDatasheet();
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleLoadSaleDatasheet = async (datasheet_id) => {
        try {
            const userData = {
                id: datasheet_id,
            };
            const data = await getSaleDatasheetListPhoneNumber(userData);
            console.log(data);
            setViewingSaleDatasheetListPhoneNumber(data.list_phone_number);
            setViewingSaleDatasheetTotal(data.total);
            setViewingSaleDatasheetViettel(data.viettel);
            setViewingSaleDatasheetVinaphone(data.vinaphone);
            setViewingSaleDatasheetMobiphone(data.mobiphone);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleNewSaleDatasheetCustomers = () => {
        const listSaleDatasheetNewCustomer = newSaleDatasheetCustomers.split('\n');
        const correctedSaleDatasheetSet = new Set([...correctedSaleDatasheetCustomers]);

        for (const customer of listSaleDatasheetNewCustomer) {
            const correctedPhoneNumber = correctPhoneNumber(customer);
            if (correctedPhoneNumber !== '' && !correctedSaleDatasheetSet.has(correctedPhoneNumber) && !viewingSaleDatasheetListPhoneNumber.includes(correctedPhoneNumber)) {
                correctedSaleDatasheetSet.add(correctedPhoneNumber);
            }
        }

        setCorrectedSaleDatasheetCustomers(Array.from(correctedSaleDatasheetSet));
        setNewSaleDatasheetCustomers('');
    }

    const handleUploadSaleDatasheetCustomers = async () => {
        try {
            const userData = {
                datasheet: viewingSaleDatasheetID,
                new_phone_number: correctedSaleDatasheetCustomers,
            };
            const data = await uploadSaleDatasheetCustomer(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await handleLoadSaleDatasheet(viewingSaleDatasheetID);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const m_lViettel = ["032", "033", "034", "035", "036", "037", "038", "039", "086", "096", "097", "098"];
    const m_lVinaphone = ["088", "091", "094", "081", "082", "083", "084", "085"];
    const m_lMobiphone = ["089", "090", "093", "070", "076", "077", "078", "079"];

    function correctPhoneNumber(p_sPhoneNumber) {
        let _sCorrectedPhoneNumber = p_sPhoneNumber.replace(/\D/g, '');

        if (_sCorrectedPhoneNumber.length < 9) {
            return "";
        }

        if (_sCorrectedPhoneNumber.substring(0, 2) === "84") {
            _sCorrectedPhoneNumber = "0" + _sCorrectedPhoneNumber.substring(2);
        }

        if (_sCorrectedPhoneNumber.substring(0, 1) !== "0") {
            _sCorrectedPhoneNumber = "0" + _sCorrectedPhoneNumber;
        }

        if (_sCorrectedPhoneNumber.substring(0, 3) === "016") {
            _sCorrectedPhoneNumber = "03" + _sCorrectedPhoneNumber.substring(2);
        } else if (["0120", "0121", "0122", "0126", "0128"].includes(_sCorrectedPhoneNumber.substring(0, 4))) {
            _sCorrectedPhoneNumber = "07" + _sCorrectedPhoneNumber.substring(3);
        } else if (["0123", "0124", "0125", "0127", "0129"].includes(_sCorrectedPhoneNumber.substring(0, 4))) {
            _sCorrectedPhoneNumber = "08" + _sCorrectedPhoneNumber.substring(3);
        }

        if (_sCorrectedPhoneNumber.length !== 10) {
            return "";
        }

        let _bNetworked = false;
        if (m_lViettel.includes(_sCorrectedPhoneNumber.substring(0, 3)) ||
            m_lVinaphone.includes(_sCorrectedPhoneNumber.substring(0, 3)) ||
            m_lMobiphone.includes(_sCorrectedPhoneNumber.substring(0, 3))) {
            _bNetworked = true;
        }

        if (!_bNetworked) {
            return "";
        }

        return _sCorrectedPhoneNumber;
    }

    const getProjectDisplayName = (id) => {
        if (id === 0) {
            return 'Chưa chọn Dự án';
        }
        const project = listSaleProject.find(project => project.id === id);
        if (project === null || project === undefined) {
            return 'Không có';
        }
        console.log(project);
        return project.display_name;
    }

    const getDatasheetDisplayName = (id) => {
        if (id === 0) {
            return 'Chưa chọn Data';
        }
        const datasheet = listSaleDatasheet.find(datasheet => datasheet.id === id);
        if (datasheet === null || datasheet === undefined) {
            return 'Không có';
        }
        return datasheet.display_name;
    }
    
    const handleDisplayNameChange = async () => {
        try {
            if (newDisplayName.length < 6) {
                alert("Tên hiển thị mới cần ít nhất 6 ký tự!");
                return;
            }
            const userData = {
                new_display_name: newDisplayName,
            };
            const data = await updateDisplayName(userData);

            const token = data.new_access_token;
            localStorage.setItem('access_token', token);

            setShowChangeDisplayNameModal(false);
            alert("Cập nhật tên hiển thị thành công");

            fetchUsers();
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };
    
    const handleCustomPasswordChange = async () => {
        try {
            if (newPassword.length < 6) {
                alert("Mật khẩu mới cần ít nhất 6 ký tự!");
                return;
            }
            const userData = {
                old_custom_password: oldPassword,
                new_custom_password: newPassword,
            };
            const data = await updateCustomPassword(userData);

            alert(data.message);
            if (data.success === true) {
                const token = data.new_access_token;
                localStorage.setItem('access_token', token);
                setShowChangePasswordModal(false);
    
                fetchUsers();
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    const onUpdateTelesaleSettingProject = (account_id) => {
        setUpdatingTelesaleSettingAccountID(account_id);
        setShowUpdateTelesaleSettingProjectModal(true);
    }

    const onUpdateTelesaleSettingDatasheet = (account_id) => {
        setUpdatingTelesaleSettingAccountID(account_id);
        setShowUpdateTelesaleSettingDatasheetModal(true);
    }

    const onUpdateTelesaleSettingNetwork = (account_id) => {
        setUpdatingTelesaleSettingAccountID(account_id);
        setShowUpdateTelesaleSettingNetworkModal(true);
    }

    const onUpdateTelesaleSettingRule = (account_id) => {
        alert('Tính năng đang phát triển');
    }

    const onClickButtonSaleCallTelesale = () => {
        navigate('/telesale');
    }

    const onClickButtonCreateTelesaleAccount = () => {
        setNewTelesaleAccountCustomLoginID('');
        setNewTelesaleAccountDisplayName('');
        setShowCreateTelesaleAccountModal(true);
    }

    const onClickButtonCreateSaleProject = () => {
        setNewSaleProjectDisplayName('');
        setShowCreateSaleProjectModal(true);
    }

    const onClickButtonCreateSaleDatasheet = () => {
        setNewSaleDatasheetDisplayName('');
        setShowCreateSaleDatasheetModal(true);
    }

    const onOpenDatasheet = (id) => {
        setViewingSaleDatasheetID(id);
        setViewingSaleDatasheetListPhoneNumber([]);
        setShowSaleDatasheetModal(true);
        handleLoadSaleDatasheet(id);
    }

    const onClickButtonUpdateDisplayName = () => {
        setNewDisplayName('');
        setShowChangeDisplayNameModal(true);
    };

    const onClickButtonUpdatePassword = () => {
        setOldPassword('');
        setNewPassword('');
        setShowChangePasswordModal(true);
    };

    const onClickButtonLogout = () => {
        const isConfirmed = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (isConfirmed) {
            localStorage.setItem('access_token', '');
            navigate('*');
        }
    };

    const toggleFolder = (id) => {
        const newOpenFolders = new Set(openDatasheetFolders);
        if (newOpenFolders.has(id)) {
          newOpenFolders.delete(id);
        } else {
          newOpenFolders.add(id);
        }
        setOpenDatasheetFolders(newOpenFolders);
    };

    const renderTree = (nodes, level = 0) => {
        return nodes.map((node) => (
          <div key={node.id}>
            <div style={{ marginLeft: level * 20 }}>
              {node.is_folder ? (
                <div>
                    <strong onClick={() => toggleFolder(node.id)} style={{ cursor: 'pointer' }}>
                        {node.display_name || 'Untitled'} {openDatasheetFolders.has(node.id) ? '[-]' : '[+]'}
                    </strong>
                    {openDatasheetFolders.has(node.id) && node.children && node.children.length > 0 && renderTree(node.children, level + 1)}
                </div>
              ) : (
                <div onClick={() => onOpenDatasheet(node.id)} style={{ cursor: 'pointer' }}>{node.display_name || 'Untitled'}</div>
              )}
            </div>
          </div>
        ));
    };

    const renderSection = () => {
        switch (selectedSection) {
            case 'telesale-setting':
                return (
                    <div className="telesale-setting">
                        <h1>Cài đặt Telesale</h1>
                        <div className="telesale-setting-list-container">
                            <div className="telesale-setting-list-title">
                                <div className="title-item">Tên</div>
                                <div className="title-item">Số SĐT</div>
                                <div className="title-item">Dự án</div>
                                <div className="title-item">Data</div>
                                <div className="title-item">Mạng</div>
                                <div className="title-item">Luật</div>
                                <div className="title-item">Thao tác</div>
                            </div>
                            
                            <div className="telesale-setting-list">
                                {listTelesaleWithSetting.map(telesale => (
                                    <div key={telesale.id}>
                                        <div className="telesale-setting-item">
                                            <label>{telesale.display_name}</label>
                                            <label>{telesale.setting.number_phone_number}</label>
                                            <label onClick={() => onUpdateTelesaleSettingProject(telesale.account_id)}>{getProjectDisplayName(telesale.setting.project)}</label>
                                            <label onClick={() => onUpdateTelesaleSettingDatasheet(telesale.account_id)}>{getDatasheetDisplayName(telesale.setting.datasheet)}</label>
                                            <label onClick={() => onUpdateTelesaleSettingNetwork(telesale.account_id)}>{telesale.setting.network}</label>
                                            <label onClick={() => onUpdateTelesaleSettingRule(telesale.account_id)}>Chưa gọi lần nào</label>
                                            <div>
                                                <button className="delete-btn" onClick={() => {handleDeleteTelesaleAccount(telesale);}}>
                                                    Xóa
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            case 'telesale-management':
                return (
                    <div className="telesale-management">
                        <h1>Quản lý Telesale</h1>
                        <div className="telesale-management-menubar">
                            <select value={selectedMonth} onChange={handleMonthChange}>
                                <option value="" disabled>Chọn tháng</option>
                                {months.map((month, index) => (
                                <option key={index} value={month}>
                                    Tháng {month}
                                </option>
                                ))}
                            </select>

                            <select value={selectedYear} onChange={handleYearChange}>
                                <option value="" disabled>Chọn năm</option>
                                {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                                ))}
                            </select>
                            <button className="telesale-management-menubar-btn" onClick={() => {loadListTelesaleWithStatistic()}}>Truy xuất</button>
                        </div>
                        <div className="telesale-statistic-list-container">
                            <div className="telesale-statistic-list-title">
                                <div className="title-item">Tên</div>
                                <div className="title-item">Tổng</div>
                                <div className="title-item">
                                    {Array.from({ length: 31 }, (_, index) => (
                                        <span key={index} className="statistic-item">{index + 1}</span>
                                    ))}
                                </div>
                            </div>

                            <div className="telesale-statistic-list-title">
                                <div className="title-item">Tất cả</div>
                                <div className="title-item">{saleStatistic.total}</div>
                                <div className="title-item">
                                    {saleStatistic.statistic.map((statistic, index) => (
                                        <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                    ))}
                                </div>
                            </div>

                            <div className="telesale-statistic-list">
                                {listTelesaleWithStatistic.length > 0 ? listTelesaleWithStatistic.map(telesale => (
                                    <div key={telesale.id}>
                                        <div className="telesale-statisic-item">
                                            <div>{telesale.display_name}</div>
                                            <div>{telesale.telesaleStatistic.total}</div>
                                            <div className="telesale-statistic">
                                                {telesale.telesaleStatistic.statistic.map((statistic, index) => (
                                                    <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )) : (<div className="telesale-item-empty">Chưa có Telesale</div>)}
                            </div>
                        </div>
                    </div>
                );
            case 'marketing-management':
                return (
                    <div>
                        <h1>Quản lý Marketing</h1>
                        <h1>Tính năng đang phát triển</h1>
                    </div>
                );
            case 'project-management':
                return (
                    <div className="project-management">
                        <h1>Quản lý Dự án</h1>
                        <div className="project-management-menubar">
                            <button className="project-management-menubar-btn" onClick={() => onClickButtonCreateSaleProject()}>Tạo Dự án mới</button>
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-title">
                                <div className="title-item">Mã Dự án</div>
                                <div className="title-item">Tên Dự án</div>
                                <div className="title-item">Thao tác</div>
                            </div>
                            <div className="project-list">
                                {listSaleProject.length > 0 ? listSaleProject.map(project => (
                                    <div key={project.id}>
                                        <div className="project-item">
                                            <label>{project.id}</label>
                                            <label>{project.display_name}</label>
                                            <div>
                                                <button className="delete-btn" onClick={() => {handleDeleteSaleProject(project);}}>
                                                    Xóa
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : (<div className="project-item-empty">Chưa có Dự án nào</div>)}
                            </div>
                        </div>
                    </div>
                );
            case 'datasheet-management':
                return (
                    <div className="project-management">
                        <h1>Quản lý Data</h1>
                        <div className="project-management-menubar">
                            <button className="project-management-menubar-btn" onClick={() => onClickButtonCreateSaleDatasheet()}>Tạo Data mới</button>
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-title">
                                <div className="title-item">Mã Data</div>
                                <div className="title-item">Tên Data</div>
                                <div className="title-item">Thao tác</div>
                            </div>
                            <div className="project-list">
                                {listSaleDatasheet.length > 0 ? listSaleDatasheet.map(datasheet => (
                                    <div key={datasheet.id}>
                                        <div className="project-item" onClick={() => onOpenDatasheet(datasheet.id)}>
                                            <label>{datasheet.id}</label>
                                            <label>{datasheet.display_name}</label>
                                            <div>
                                                <button className="delete-btn" onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteSaleDatasheet(datasheet);
                                                    }}>
                                                    Xóa
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : (<div className="project-item-empty">Chưa có Dự án nào</div>)}
                            </div>
                        </div>
                        {/* {renderTree(listSaleDatasheet)} */}
                    </div>
                );
            default:
                return <div><h1>404</h1></div>;
        }
    };

    return (
        <div className="layout">
            <div className="sidebar">
                <h3>Xin chào, {displayName}</h3>
                <div className='seperate-line'/>
                <button className={`sidebar-btn ${selectedSection === 'telesale-setting' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-setting')}>Cài đặt Telesale</button>
                <button className={`sidebar-btn ${selectedSection === 'project-management' ? 'active' : ''}`} onClick={() => setSelectedSection('project-management')}>Quản lý Dự án</button>
                <button className={`sidebar-btn ${selectedSection === 'datasheet-management' ? 'active' : ''}`} onClick={() => setSelectedSection('datasheet-management')}>Quản lý Data</button>
                <button className={`sidebar-btn ${selectedSection === 'telesale-management' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-management')}>Quản lý Telesale</button>
                <button className={`sidebar-btn ${selectedSection === 'marketing-management' ? 'active' : ''}`} onClick={() => setSelectedSection('marketing-management')}>Quản lý Marketing</button>
                <button className="sidebar-action-btn" onClick={onClickButtonSaleCallTelesale}>Tự gọi điện telesale</button>
                <div className='seperate-line'/>
                <button className="sidebar-btn" onClick={onClickButtonCreateTelesaleAccount}>Tạo tài khoản Telesale</button>
                <button className="sidebar-btn" onClick={onClickButtonUpdateDisplayName}>Đổi tên hiển thị</button>
                <button className="sidebar-btn" onClick={onClickButtonUpdatePassword}>Đổi mật khẩu</button>
                <div className='seperate-line'/>
                <button className="sidebar-logout-btn" onClick={onClickButtonLogout}>Đăng xuất</button>
            </div>
            <div className="content">
                {renderSection()}
            </div>

            {/* Modal Tạo Tài Khoản Telesale */}
            {showCreateTelesaleAccountModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateTelesaleAccountModal(false)}>✖</button>
                        <h2>Tạo tài khoản Telesale</h2>
                        <input
                            type="text"
                            placeholder="Tài khoản đăng nhập"
                            value={newTelesaleAccountCustomLoginID}
                            onChange={(e) => setNewTelesaleAccountCustomLoginID(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Tên hiển thị"
                            value={newTelesaleAccountDisplayName}
                            onChange={(e) => setNewTelesaleAccountDisplayName(e.target.value)}
                            required
                        />
                        <div className="modal-buttons">
                            <button onClick={() => handleCreateTelesaleAccount()}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Đổi Dự án gọi Telesale */}
            {showUpdateTelesaleSettingProjectModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowUpdateTelesaleSettingProjectModal(false)}>✖</button>
                        <h2>Đổi Dự án gọi Telesale</h2>
                        <div className="modal-buttons-container">
                            {listSaleProject.map((project) => (
                                <div className="modal-buttons">
                                    <button key={project.id} className="modal-buttons" onClick={() => handleUpdateTelesaleSettingProject(project.id)}>
                                        {project.display_name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Đổi Data gọi Telesale */}
            {showUpdateTelesaleSettingDatasheetModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowUpdateTelesaleSettingDatasheetModal(false)}>✖</button>
                        <h2>Đổi Data gọi Telesale</h2>
                        <div className="modal-buttons-container">
                            {listSaleDatasheet.map((datasheet) => (
                                <div className="modal-buttons">
                                    <button key={datasheet.id} className="modal-buttons" onClick={() => handleUpdateTelesaleSettingDatasheet(datasheet.id)}>
                                        {datasheet.display_name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Đổi mạng gọi Telesale */}
            {showUpdateTelesaleSettingNetworkModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowUpdateTelesaleSettingNetworkModal(false)}>✖</button>
                        <h2>Đổi mạng gọi Telesale</h2>
                        <div className="modal-buttons-container">
                            <div className="modal-buttons">
                                <button onClick={() => handleUpdateTelesaleSettingNetwork('ALL')}>Tất cả các mạng</button>
                            </div>
                            <div className="modal-buttons">
                                <button onClick={() => handleUpdateTelesaleSettingNetwork('VIETTEL')}>Viettel</button>
                            </div>
                            <div className="modal-buttons">
                                <button onClick={() => handleUpdateTelesaleSettingNetwork('VINAPHONE')}>Vinaphone</button>
                            </div>
                            <div className="modal-buttons">
                                <button onClick={() => handleUpdateTelesaleSettingNetwork('MOBIPHONE')}>Mobiphone</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Tạo Dự Án */}
            {showCreateSaleProjectModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateSaleProjectModal(false)}>✖</button>
                        <h2>Tạo Dự án mới</h2>
                        <input
                            type="text"
                            placeholder="Tên Dự án"
                            value={newSaleProjectDisplayName}
                            onChange={(e) => setNewSaleProjectDisplayName(e.target.value)}
                            required
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateSaleProject}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Tạo Data */}
            {showCreateSaleDatasheetModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateSaleDatasheetModal(false)}>✖</button>
                        <h2>Tạo Data mới</h2>
                        <input
                            type="text"
                            placeholder="Tên Data"
                            value={newSaleDatasheetDisplayName}
                            onChange={(e) => setNewSaleDatasheetDisplayName(e.target.value)}
                            required
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateSaleDatasheet}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal nội dung Data */}
            {showSaleDatasheetModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowSaleDatasheetModal(false)}>✖</button>
                        <h2>Data</h2>
                        <div>Tổng số: {viewingSaleDatasheetTotal}</div>
                        <div>Viettel: {viewingSaleDatasheetViettel}</div>
                        <div>Vinaphone: {viewingSaleDatasheetVinaphone}</div>
                        <div>Mobiphone: {viewingSaleDatasheetMobiphone}</div>
                        <textarea
                            placeholder="Nhập số điện thoại cần tải lên"
                            value={newSaleDatasheetCustomers}
                            onChange={(e) => setNewSaleDatasheetCustomers(e.target.value)}
                            required
                        />
                        <div className="modal-buttons">
                            <button onClick={handleNewSaleDatasheetCustomers}>Chuẩn hóa</button>
                        </div>

                        {correctedSaleDatasheetCustomers.length > 0 && (
                            <div>
                                <div>Đã chuẩn hóa được: {correctedSaleDatasheetCustomers.length} sđt</div>
                                <div className="modal-buttons">
                                    <button onClick={handleUploadSaleDatasheetCustomers}>Tải lên</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            
            {/* Modal Đổi Tên Hiển Thị */}
            {showChangeDisplayNameModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangeDisplayNameModal(false)}>✖</button>
                        <h2>Đổi Tên Hiển Thị</h2>
                        <input
                            type="text"
                            placeholder="Tên hiển thị mới"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                            required
                        />
                        <div className="modal-buttons">
                            <button onClick={handleDisplayNameChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Đổi Mật Khẩu */}
            {showChangePasswordModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangePasswordModal(false)}>✖</button>
                        <h2>Đổi Mật Khẩu</h2>
                        <div className="password-field">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                placeholder="Mật khẩu cũ"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                            />
                            <span className="eye-icon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="password-field">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Mật khẩu mới"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="modal-buttons">
                            <button onClick={handleCustomPasswordChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sale;