import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import icon mắt
import '../css/AdminTelesale.css';
import '../css/Modal.css';
import { getAccount, updateDisplayName, updateCustomPassword, adminTelesaleGetListRoom } from './API';

const AdminTelesale = () => {

    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [selectedSection, setSelectedSection] = useState('telesale-management');

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
    const years = [2024, 2025, 2026];

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };
    
    const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    
    const [company, setCompany] = useState({ total: 0, statistic: [] });
    const [listRoom, setListRoom] = useState([]);
    const toggleSaleVisibility = (roomId) => {
        setListRoom((prevList) =>
            prevList.map((room) =>
              room.id === roomId ? {...room, showSales: !room.showSales } : room )
        );
    };
    const toggleTelesalesVisibility = (roomId, saleId) => {
        setListRoom((prevList) =>
            prevList.map((room) =>
              room.id === roomId
                ? {
                    ...room,
                    listSale: room.listSale.map((sale) =>
                      sale.id === saleId
                        ? { ...sale, showTelesales: !sale.showTelesales }
                        : sale
                    )
                  }
                : room
            )
        );
    };
    
    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };
    
    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();

            loadListRoom();
        }
        else {
            navigate('/');
        }
    }, [navigate]);
    
    const loadListRoom = async () => {
        try {
            const userData = {
                month: selectedMonth,
                year: selectedYear
            };
            setListRoom([]);
            const listRoom = await adminTelesaleGetListRoom(userData);
            listRoom.forEach(room => {
                let roomTotal = 0;
                let roomTelesaleStatistic = Array(31).fill(0);
            
                room.listSale.forEach(sale => {
                    roomTotal += sale.telesaleStatistic.total;
                    
                    sale.telesaleStatistic.statistic.forEach((value, index) => {
                        roomTelesaleStatistic[index] += value;
                    });
                    
                    sale.listTelesale.forEach(telesale => {
                        roomTotal += telesale.telesaleStatistic.total;
                        
                        telesale.telesaleStatistic.statistic.forEach((value, index) => {
                            roomTelesaleStatistic[index] += value;
                        });
                    });
                });
            });

            const calculatedCompany = listRoom.reduce(
                (company, room) => {
                  const roomStatistic = room.telesaleStatistic || { total: 0, statistic: [] };
                  company.total += roomStatistic.total;
                  roomStatistic.statistic.forEach((value, index) => {
                    company.statistic[index] = (company.statistic[index] || 0) + value;
                  });
          
                  return company;
                },
                { total: 0, statistic: Array(31).fill(0) }
            );
            setCompany(calculatedCompany);

            setListRoom(listRoom);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }
        
    const handleDisplayNameChange = async () => {
        try {
            if (newDisplayName.length < 6) {
                alert("Tên hiển thị mới cần ít nhất 6 ký tự!");
                return;
            }
            const userData = {
                new_display_name: newDisplayName,
            };
            const data = await updateDisplayName(userData);

            const token = data.new_access_token;
            localStorage.setItem('access_token', token);

            setShowChangeDisplayNameModal(false);
            alert("Cập nhật tên hiển thị thành công");

            fetchUsers();
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };
        
    const handleCustomPasswordChange = async () => {
        try {
            if (newPassword.length < 6) {
                alert("Mật khẩu mới cần ít nhất 6 ký tự!");
                return;
            }
            const userData = {
                old_custom_password: oldPassword,
                new_custom_password: newPassword,
            };
            const data = await updateCustomPassword(userData);

            alert(data.message);
            if (data.success === true) {
                const token = data.new_access_token;
                localStorage.setItem('access_token', token);
                setShowChangePasswordModal(false);
    
                fetchUsers();
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    const onClickButtonChangeDisplayName = () => {
        setNewDisplayName('');
        setShowChangeDisplayNameModal(true);
    };

    const onClickButtonChangePassword = () => {
        setOldPassword('');
        setNewPassword('');
        setShowChangePasswordModal(true);
    };

    const onClickButtonLogout = () => {
        const isConfirmed = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (isConfirmed) {
            localStorage.setItem('access_token', '');
            navigate('*');
        }
    };
    
    const renderSection = () => {
        switch (selectedSection) {
            case 'telesale-management':
                return (
                    <div className="telesale-management">
                        <h1>Quản lý Telesale</h1>
                        <div className="telesale-management-menubar">
                            <select value={selectedMonth} onChange={handleMonthChange}>
                                <option value="" disabled>Chọn tháng</option>
                                {months.map((month, index) => (
                                <option key={index} value={month}>
                                    Tháng {month}
                                </option>
                                ))}
                            </select>

                            <select value={selectedYear} onChange={handleYearChange}>
                                <option value="" disabled>Chọn năm</option>
                                {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                                ))}
                            </select>
                            <button className="telesale-management-menubar-btn" onClick={() => {loadListRoom()}}>Truy xuất</button>
                        </div>
                        <div className="sale-list-container">
                            <div className="sale-list-title">
                                <div className="title-item">Tên</div>
                                <div className="title-item">Tổng</div>
                                <div className="title-item">
                                    {Array.from({ length: 31 }, (_, index) => (
                                        <span key={index} className="statistic-item">{index + 1}</span>
                                    ))}
                                </div>
                                <div className="title-item">Thao tác</div>
                            </div>

                            <div className="sale-list-title">
                                <div className="title-item">Toàn công ty</div>
                                <div className="title-item">{company.total}</div>
                                <div className="title-item">
                                    {company.statistic.map((statistic, index) => (
                                        <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                    ))}
                                </div>
                                <div className="title-item"></div>
                            </div>
                            
                            <div className="room-list">
                                {listRoom.length > 0 ? (listRoom.map(room => (
                                    <div key={room.id}>
                                        <div className="room-item">
                                            <div className="room-item-title"> {room.display_name}</div>
                                            <div>{room.telesaleStatistic.total}</div>
                                            <div className="telesale-statistic">
                                                {room.telesaleStatistic.statistic.map((statistic, index) => (
                                                    <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                                ))}
                                            </div>
                                            <div>
                                                <button onClick={() => toggleSaleVisibility(room.id)}>
                                                    {room.showSales ? '▲' : '▼'}
                                                </button>
                                            </div>
                                        </div>
                                        {room.showSales && (
                                            <div>
                                                {room.listSale.length > 0 ? (room.listSale.map(sale => (
                                                    <div key={sale.id}>
                                                        <div className="sale-item">
                                                            <div>{sale.display_name}</div>
                                                            <div>{sale.telesaleStatistic.total}</div>
                                                            <div className="telesale-statistic">
                                                                {sale.telesaleStatistic.statistic.map((statistic, index) => (
                                                                    <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                <button onClick={() => toggleTelesalesVisibility(room.id, sale.id)}>
                                                                    {sale.showTelesales ? '▲' : '▼'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                        {sale.showTelesales && (
                                                            <div className="telesales-list">
                                                                {sale.listTelesale.length > 0 ? (
                                                                    sale.listTelesale.map(telesale => (
                                                                    <div key={telesale.id} className="telesale-item">
                                                                        <div>{telesale.display_name}</div>
                                                                        <div>{telesale.telesaleStatistic.total}</div>
                                                                        <div className="telesale-statistic">
                                                                            {telesale.telesaleStatistic.statistic.map((statistic, index) => (
                                                                                <span key={index} className="statistic-item">{statistic === 0 ? '-' : statistic}</span>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    ))
                                                                ) : (<div className="telesale-item-empty">Chưa có Telesale</div>)}
                                                            </div>)}
                                                    </div>
                                                ))) : (<div className="telesale-item-empty">Chưa có Sale</div>)}
                                            </div>
                                        )}
                                    </div>
                                ))) : (<div className="telesale-item-empty">Chưa có Phòng ban</div>)}
                            </div>
                        </div>
                    </div>
                );
            default:
                return <div><h1>404</h1></div>;
        }
    };

    return (
        <div className="layout">
            <div className="sidebar">
                <h3>Xin chào, {displayName}</h3>
                <div className='seperate-line'/>
                <button className={`sidebar-btn ${selectedSection === 'telesale-management' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-management')}>Quản lý Telesale</button>
                <button className="sidebar-btn" onClick={onClickButtonChangeDisplayName}>Đổi tên hiển thị</button>
                <button className="sidebar-btn" onClick={onClickButtonChangePassword}>Đổi mật khẩu</button>
                <button className="sidebar-logout-btn" onClick={onClickButtonLogout}>Đăng xuất</button>
            </div>
            <div className="content">
                {renderSection()}
            </div>
            
            {/* Modal Đổi Tên Hiển Thị */}
            {showChangeDisplayNameModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangeDisplayNameModal(false)}>✖</button>
                        <h2>Đổi Tên Hiển Thị</h2>
                        <input
                            type="text"
                            placeholder="Tên hiển thị mới"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleDisplayNameChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Đổi Mật Khẩu */}
            {showChangePasswordModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangePasswordModal(false)}>✖</button>
                        <h2>Đổi Mật Khẩu</h2>
                        <div className="password-field">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                placeholder="Mật khẩu cũ"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="password-field">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Mật khẩu mới"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="modal-buttons">
                            <button onClick={handleCustomPasswordChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminTelesale;