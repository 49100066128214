import axios from 'axios';

const api = axios.create({
  baseURL: 'https://duongpm13.click:4000',
  headers: {
    'Content-Type': 'application/json',
  },
});

// account
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const login = async(userData) => {
    try {
      const response = await api.post('/account/login', userData);
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

export const getAccount = async() => {
    try {
      const response = await api.get('/account/account');
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

export const updateDisplayName = async(userData) => {
    try {
      const response = await api.post('/account/update_display_name', userData);
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

export const updateCustomPassword = async(userData) => {
    try {
      const response = await api.post('/account/update_custom_password', userData);
      return response.data;
    }
    catch (error) {
      throw error;
    }
}
// admin_telesale
export const adminTelesaleGetListRoom = async(userData) => {
  try {
    const response = await api.post('/admin_telesale/get_list_room', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

// lead
export const getListSaleWithTelesale = async(userData) => {
  try {
    const response = await api.post('/lead/get_list_sale_with_telesale', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleAccount = async(userData) => {
  try {
    const response = await api.post('/lead/create_sale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const deleteSaleAccount = async(userData) => {
  try {
    const response = await api.post('/lead/delete_sale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

// sale
export const getListTelesaleWithSetting = async() => {
  try {
    const response = await api.get('/sale/get_list_telesale_with_setting');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getListTelesaleWithStatistic = async(userData) => {
  try {
    const response = await api.post('/sale/get_list_telesale_with_statistic', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createTelesaleAccount = async(userData) => {
  try {
    const response = await api.post('/sale/create_telesale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const deleteTelesaleAccount = async(userData) => {
  try {
    const response = await api.post('/sale/delete_telesale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const updateTelesaleSettingNetwork = async(userData) => {
  try {
    const response = await api.post('/sale/update_telesale_setting_network', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const updateTelesaleSettingProject = async(userData) => {
  try {
    const response = await api.post('/sale/update_telesale_setting_project', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const updateTelesaleSettingDatasheet = async(userData) => {
  try {
    const response = await api.post('/sale/update_telesale_setting_datasheet', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getListSaleProject = async() => {
  try {
    const response = await api.get('/sale/get_list_sale_project');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getListSaleDatasheet = async() => {
  try {
    const response = await api.get('/sale/get_list_sale_datasheet');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getSaleDatasheetListPhoneNumber = async(userData) => {
  try {
    const response = await api.post('/sale/get_sale_datasheet_list_phone_number', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const uploadSaleDatasheetCustomer = async(userData) => {
  try {
    const response = await api.post('/sale/upload_sale_datasheet_customer', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleProject = async(userData) => {
  try {
    const response = await api.post('/sale/create_sale_project', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const deleteSaleProject = async(userData) => {
  try {
    const response = await api.post('/sale/delete_sale_project', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleDatasheet = async(userData) => {
  try {
    const response = await api.post('/sale/create_sale_datasheet', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const deleteSaleDatasheet = async(userData) => {
  try {
    const response = await api.post('/sale/delete_sale_datasheet', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

// telesale
export const getPhoneNumber = async() => {
  try {
    const response = await api.get('/telesale/get_phone_number');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createPhoneCall = async(userData) => {
  try {
    const response = await api.post('/telesale/create_phone_call', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


// utils
export const getListRoom = async() => {
  try {
    const response = await api.get('/utils/get_list_room');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export default api;